<template>
  <v-row>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        แก้ไขข่าว
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ-นามสกุล (Full name)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="form.full_name"
              placeholder="ชื่อ-นามสกุล (Full name)"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">เพศ (Gender)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="form.gender"
              placeholder="เพศ (Gender)"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">วัน/เดือน/ปีเกิด (Birthday)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="form.birth_day"
              placeholder="วัน/เดือน/ปีเกิด (Birthday)"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">เบอร์โทร (Tel.)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="form.tel"
              placeholder="เบอร์โทร (Tel.)"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#2AB3A3" disabled>บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {
        full_name: "",
        gender: "",
        birth_day: "",
        tel: "",
      },
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    var Blogdata = JSON.parse(Decode.decode(localStorage.getItem("Blogdata")));
    console.log("Blogdata", Blogdata);
    this.id = Blogdata.id;
    this.title = Blogdata.title;
    this.content = Blogdata.description;
    this.activeFlag = Blogdata.activeFlag;
    this.showImage = Blogdata.blogImg;
    this.pic1 = Blogdata.blogImg;
    this.picture_1 = Blogdata.blogImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          title: this.title,
          // description: this.brandDescription,
          // activeFlag: this.activeFlag,
          description: this.content,
          blogImg: this.pic1,
        };
        if (this.activeFlag == true) {
          data.activeFlag = "1";
        } else if (this.activeFlag == false) {
          data.activeFlag = "0";
        }
        var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/blogs/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข่าวสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageNews");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข่าวไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>